import React from 'react';
import ARU from '../img/ARU.png';
import BANGOR from '../img/BANGOR.png';
import DCU from '../img/DCU.png';
import LJMU from '../img/LJMU.png';
import MU from '../img/MU.png';
import UCC from '../img/UCC.png';
import UL from '../img/UL.png';
import '../App.css';

const Home = () => {
  const open = (link) => {
    window.open(link, '_blank')
  }
  return (
    <div style={{ minHeight: 500, height: window.innerHeight - 80, marginTop: 80 }}>
      <div className='container1'>
        <img alt="" src={ARU} onClick={() => open('https://aru.mycampusconnect.ie/')} />
        <img alt="" src={BANGOR} onClick={() => open('https://bangor.mycampusconnect.ie/')} />
        <img alt="" src={DCU} onClick={() => open('https://dcu.mycampusconnect.ie/')} />
        <img alt="" src={LJMU} onClick={() => open('https://ljmuinternational.mycampusconnect.ie/')} />
      </div>
      <div className='container2'>
        <img alt="" src={MU} onClick={() => open('https://maynoothuniversity.mycampusconnect.ie/')} />
        <img alt="" src={UCC} onClick={() => open('https://ucc.mycampusconnect.ie/')} />
        <img alt="" src={UL} onClick={() => open('https://ul.mycampusconnect.ie/')} />
      </div>
      <p className='line'>Looking for help? Please send us an email to
        <a href='mailto:support@campusconnect.ie'> support@campusconnect.ie </a>
      </p>
    </div>
  )
};

export default Home;