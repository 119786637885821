import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Home from './components/Home';
import logo from './logo.png';
import './App.css';
// import About from './components/About';

function App() {
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <img src={logo} alt=""/>
        </header>
        <Routes>
          <Route exact path="/*" element={<Home />} />
        </Routes>
        <div className="footer">
          <Link to="/" > Terms and Conditions </Link>
          <Link to="/about" > Privacy Policy </Link>
          <Link to="/" > Cookie Policy </Link>
          <Link to="/about" > Cookie Settings </Link>
        </div>
      </Router>
    </div>
  );
}

export default App;
